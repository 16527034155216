import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import { Label } from '@radix-ui/react-label'
import * as Select from '@radix-ui/react-select'
import classnames from 'classnames'
import React from 'react'
import * as styles from './pagination-controls.css.ts'

type PaginationControlsProps = {
  tableLabel: string
  pageIndex: number
  pageSize: number
  rowCount: number
  onNextPage: () => void
  onPreviousPage: () => void
  onChangePageSize: undefined | ((pageSize: number) => void)
}

/**
 *
 * @param pageIndex is zero indexed
 * @param onChangePageSize controls if page size select is shown
 */
export const PaginationControls = ({
  tableLabel,
  pageIndex,
  pageSize,
  rowCount,
  onNextPage,
  onPreviousPage,
  onChangePageSize,
}: PaginationControlsProps) => {
  if (rowCount === 0) return <></>

  const totalPages = Math.ceil(rowCount / pageSize) - 1
  const rowMin = (pageIndex + 1) * pageSize - pageSize + 1
  const rowMax = Math.min((pageIndex + 1) * pageSize, rowCount)

  const isLeftChevronActive = pageIndex > 0
  const isRightChevronActive = pageIndex < totalPages

  return (
    <nav aria-label={`${tableLabel} Table Pagination Navigation`} className={styles.paginationControls}>
      <div>
        <button onClick={onPreviousPage} disabled={!isLeftChevronActive} aria-label={`Go to Previous Page`}>
          <ChevronLeftIcon className={styles.chevron[isLeftChevronActive ? 'active' : 'disabled']} />
        </button>
        <span className={styles.rowsCount}>
          <span className={styles.currentRowsCount}>
            {rowMin}-{rowMax}
          </span>{' '}
          of <span className={styles.maxRowsCount}>{rowCount}</span>
        </span>
        <button onClick={onNextPage} disabled={!isRightChevronActive} aria-label={'Go to Next Page'}>
          <ChevronRightIcon className={styles.chevron[isRightChevronActive ? 'active' : 'disabled']} />
        </button>
      </div>
      {onChangePageSize && (
        <div>
          <Select.Root defaultValue={String(pageSize)} onValueChange={value => onChangePageSize(Number(value))}>
            <Select.Trigger className={styles.selectTrigger} id={`${tableLabel.toLowerCase()}-page-size-select`}>
              <Select.Value />
              <Select.Icon>
                <TriangleDownIcon className={styles.chevronDown} />
                <TriangleUpIcon className={styles.chevronUp} />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content
                className={styles.selectContent}
                onCloseAutoFocus={e => {
                  e.preventDefault()
                }}
                position="popper"
                side="bottom"
                avoidCollisions={false}
              >
                <SelectItem value={'10'} text="10" />
                <SelectItem value={'25'} text="25" />
                <SelectItem value={'50'} text="50" />
              </Select.Content>
            </Select.Portal>
          </Select.Root>
          <Label htmlFor={`${tableLabel.toLowerCase()}-page-size-select`} className={styles.label}>
            per page
          </Label>
        </div>
      )}
    </nav>
  )
}

interface SelectItemProps {
  children?: React.ReactNode
  text: string
  className?: string
  value: string
  disabled?: boolean
}

const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, text, className, value, ...props }, forwardedRef) => {
    return (
      <Select.Item className={classnames(styles.selectItem, className)} value={value} {...props} ref={forwardedRef}>
        {children}
        <Select.ItemText>{text}</Select.ItemText>
        <Select.ItemIndicator className={styles.selectItemIndicator}>
          <CheckIcon className={styles.checkIcon} />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)
