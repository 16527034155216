import React from 'react'
import { Navigate } from 'react-router-dom'

export class ErrorBoundary extends React.Component<{
  children: React.ReactNode
  locationPathname: string
}> {
  state = { isError: false, isAuthError: false }

  static getDerivedStateFromError(error: Error) {
    if (error.message === '499 Response') return { isAuthError: true, isError: true }
    if (error.message === '302 Response') return { isAuthError: true, isError: true }
    return { isError: true }
  }

  render() {
    if (location.pathname === '/login') return this.props.children
    if (this.state.isAuthError) return <Navigate to={`/login?redirect_path=${this.props.locationPathname}`} replace />
    if (this.state.isError) return <div>An error occurred. Please refresh the page.</div>
    return this.props.children
  }
}
