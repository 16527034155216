import { MarkGithubIcon } from '@primer/octicons-react'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { PreviewFixDrawer, PreviewFixDrawerPure } from '../components/preview-fix-drawer'
import { SeverityBadge } from '../components/severity-badge'
import { Spinner } from '../components/spinner'
import { Pagination, Table } from '../components/table'
import { Tooltip } from '../components/tooltip'
import { utilities } from '../main.css.ts'
import {
  useGetFindings,
  useGetInstallations,
  useGetRepositories,
  useGetScanAnalysis,
} from '../utils/api-client/user-platform-api-hooks'
import { FindingsWithFixId, FindingWithFixId, ScanAnalysis, Tool } from '../utils/api-client/user-platform-api-schemas'
import { useTheme } from '../utils/higher-order-components/with-theme'
import { useAddToast } from '../utils/higher-order-components/with-toasts'
import * as styles from './analysis-details-page.css'

export function AnalysisDetailsPage() {
  const { installationId, repositoryId, analysisId, scanId } = useParams()
  if (!installationId || !repositoryId || !analysisId || !scanId) {
    throw new Error('Missing required parameters: installationId, repositoryId, analysisId, or scanId')
  }

  const { theme } = useTheme()
  const { handleAddToastWithTimeout } = useAddToast()
  const { data: installations } = useGetInstallations()
  const installation = installations?.find(installation => installation.id === Number(installationId))
  const { data: repositories } = useGetRepositories(installation?.id ? [installation.id] : [])
  const { data: scanAnalysis } = useGetScanAnalysis({ analysisId, scanId })

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })
  const { data } = useGetFindings({
    analysisId,
    scanId,
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })
  const findings = data?.data
  const repository = repositories?.find(repo => repo.id === Number(repositoryId))

  return (
    <AnalysisDetailsPagePure
      theme={theme}
      accountLogin={installation?.account.login}
      repositoryName={repository?.name}
      scanAnalysis={scanAnalysis}
      findings={findings}
      getTotalFindings={() => data?.total}
      findingsTable={
        <FindingsTablePure
          pagination={pagination}
          setPagination={setPagination}
          findings={findings}
          getTotalFindings={() => data?.total}
          previewFixDrawer={({ findingId }) => (
            <PreviewFixDrawer
              analysisId={analysisId}
              scanId={scanId}
              findingId={findingId}
              trigger={<button className={styles.fixPreviewButton}>Preview</button>}
              handleAddToastWithTimeout={handleAddToastWithTimeout}
            />
          )}
        />
      }
    />
  )
}

export function AnalysisDetailsPagePure({
  theme = 'dark',
  accountLogin,
  repositoryName,
  scanAnalysis,
  findings,
  getFixesCount = (findings?: FindingsWithFixId) =>
    findings?.filter(finding => finding.fix_id !== null && finding.fix_id !== undefined).length,
  getTotalFindings = (findings?: FindingsWithFixId) => findings?.length,
  findingsTable = <FindingsTableWithPaginationUIState findings={findings} />,
}: {
  theme?: ReturnType<typeof useTheme>['theme']
  accountLogin?: string
  repositoryName?: string
  scanAnalysis?: ScanAnalysis
  findings?: FindingsWithFixId
  getFixesCount?: (findings?: FindingsWithFixId) => number | undefined
  getTotalFindings?: (findings?: FindingsWithFixId) => number | undefined
  findingsTable?: ReturnType<typeof FindingsTablePure>
}) {
  const fixesCount = getFixesCount(findings)
  const totalFindings = getTotalFindings(findings)

  return (
    <div className={styles.analysisDetailsContainer}>
      <header className={styles.analysisDetailsHeader} role="banner">
        {scanAnalysis ? (
          <ScanInfo scanAnalysis={scanAnalysis} theme={theme} />
        ) : (
          <Spinner label="Loading scan analysis..." />
        )}
        <section className={styles.repoInfoContainer} aria-labelledby="repo-info-header">
          <h2 id="repo-info-header" className={utilities.visuallyHidden}>
            Repository Information
          </h2>
          {accountLogin && repositoryName ? (
            <RepoAndOwnerInfo accountLogin={accountLogin} repositoryName={repositoryName} />
          ) : (
            <Spinner label="Loading user and repository details..." />
          )}
          {findings && typeof fixesCount === 'number' && typeof totalFindings === 'number' && scanAnalysis?.tool && (
            <FindingsBar fixesCount={fixesCount} totalFindings={totalFindings} tool={scanAnalysis.tool} />
          )}
        </section>
        <section className={styles.snykLinkContainer} role="complementary" aria-labelledby="metrics-header">
          <h2 id="metrics-header" className={utilities.visuallyHidden}>
            Metrics and External Link
          </h2>
          {scanAnalysis?.html_url && (
            <a href={scanAnalysis?.html_url} target="_blank" rel="noopener noreferrer" className={styles.snykLink}>
              View in {mapToolToLogoAndName(scanAnalysis.tool, theme).name}
              <ExternalLinkIcon className={styles.externalLinkIcon} />
            </a>
          )}
          {findings && typeof fixesCount === 'number' && typeof totalFindings === 'number' && (
            <FindingsMetrics fixesCount={fixesCount} totalFindings={totalFindings} />
          )}
        </section>
      </header>
      {findingsTable}
    </div>
  )
}

function FindingsTableWithPaginationUIState({ findings }: { findings?: FindingsWithFixId }) {
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })

  return <FindingsTablePure pagination={pagination} setPagination={setPagination} findings={findings} />
}

function FindingsTablePure({
  pagination,
  setPagination,
  findings,
  getTotalFindings = (findings?: FindingsWithFixId) => findings?.length,
  previewFixDrawer = () => (
    <PreviewFixDrawerPure
      changesets={[
        {
          codemod_id: 'appscan:java/improper-exception-handling',
          path: 'src/main/java/com/hcl/appscan/sdk/http/HttpsClient.java',
          diff: '--- HttpsClient.java\n+++ HttpsClient.java\n@@ -281,7 +281,6 @@\n HttpsURLConnection conn = null;\n conn = (HttpsURLConnection) requestURL.openConnection();\n conn.setRequestMethod(method.name());\n-conn.setReadTimeout(0);\n \n // HTTP headers\n if (headerProperties != null) {',
          description:
            'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
        },
        {
          codemod_id: 'appscan:java/improper-exception-handling',
          path: 'src/main/java/com/hcl/appscan/sdk/scanners/sast/xml/DOMWriter.java',
          diff: '--- DOMWriter.java\n+++ DOMWriter.java\n@@ -239,8 +239,7 @@\n try {\n m_stream.close();\n } catch (IOException e) {\n-e.printStackTrace();\n-}\n+}\n m_stream = null;\n }\n }',
          description:
            'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
        },
        {
          codemod_id: 'appscan:java/improper-exception-handling',
          path: 'src/main/java/com/hcl/appscan/sdk/scanners/sast/xml/XmlWriter.java',
          diff: '--- XmlWriter.java\n+++ XmlWriter.java\n@@ -43,10 +43,8 @@\n try {\n initialize(directory);\n } catch (TransformerConfigurationException e) {\n-e.printStackTrace();\n-} catch (ParserConfigurationException e) {\n-e.printStackTrace();\n-}\n+} catch (ParserConfigurationException e) {\n+}\n m_config = new DOMWriter(directory, m_configFileName, m_builder);\n }\n',
          description:
            'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
        },
      ]}
      codemod={{
        codemod_id: 'appscan:java/improper-exception-handling',
        name: '(AppScan) Remediate Improper Handling of Exceptional Conditions',
        description:
          'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
      }}
    />
  ),
}: {
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
  findings?: FindingsWithFixId
  getTotalFindings?: (findings?: FindingsWithFixId) => number | undefined
  previewFixDrawer?: (props: { findingId: string }) => ReturnType<typeof PreviewFixDrawer>
}) {
  const totalFindings = getTotalFindings(findings)

  const table = useReactTable<FindingWithFixId>({
    data: findings ?? [],
    columns: [
      {
        accessorKey: 'severity',
        meta: { width: '5%' },
        header: () => <span className={styles.header}>SEVERITY</span>,
        cell: ({ row }) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <SeverityBadge variant={row.original.severity} />
          </div>
        ),
      },
      {
        accessorKey: 'title',
        meta: { width: '30%' },
        header: () => (
          <span className={styles.header} style={{ justifyContent: 'flex-start' }}>
            FINDING
          </span>
        ),
        cell: ({ row }) => (
          <>
            <p className={styles.findingTitle}>
              {row.original.title && row.original.title.length > 52
                ? row.original.title.slice(0, 49) + '...'
                : (row.original.title ?? 'No title')}
            </p>
            <p className={styles.findingRule}>{row.original.rule}</p>
          </>
        ),
      },
      {
        accessorKey: 'suggestedStatus',
        meta: { width: '14%' },
        header: () => (
          <span className={classNames(styles.header, styles.grayText)}>
            SUGGESTED STATUS{' '}
            <Tooltip>
              <p className={styles.tooltipTextHeader}>Coming soon</p>
              <p className={styles.tooltipText}>Stay tuned for Triage analysis on scan results</p>
            </Tooltip>
          </span>
        ),
        cell: () => <span className={styles.placeholderCell}>-</span>,
      },
      {
        accessorKey: 'severityUpdate',
        meta: { width: '14%' },
        header: () => (
          <span className={classNames(styles.header, styles.grayText)}>
            SEVERITY UPDATE{' '}
            <Tooltip>
              <p className={styles.tooltipTextHeader}>Coming soon</p>
              <p className={styles.tooltipText}>Stay tuned for Triage analysis on scan results</p>
            </Tooltip>
          </span>
        ),
        cell: () => <span className={styles.placeholderCell}>-</span>,
      },
      {
        accessorKey: 'summary',
        meta: { width: '14%' },
        header: () => (
          <span className={classNames(styles.header, styles.grayText)}>
            SUMMARY{' '}
            <Tooltip>
              <p className={styles.tooltipTextHeader}>Coming soon</p>
              <p className={styles.tooltipText}>Stay tuned for Triage analysis on scan results</p>
            </Tooltip>
          </span>
        ),
        cell: () => <span className={styles.placeholderCell}>-</span>,
      },
      {
        accessorKey: 'fix',
        meta: { width: '10%' },
        header: () => <span className={styles.header}>FIX</span>,
        cell: ({ row }) =>
          row.original.fix_id ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {previewFixDrawer({ findingId: row.original.id })}
            </div>
          ) : (
            <span className={styles.placeholderCell}>-</span>
          ),
      },
    ],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Table
      table={table}
      isLoading={findings === undefined}
      pagination={pagination}
      setPagination={setPagination}
      tableLabel="Findings"
      rowCount={totalFindings}
    />
  )
}

export const mapToolToLogoAndName = (tool: Tool, mode: 'light' | 'dark') => {
  const suffix = mode === 'light' ? 'Lt' : 'Dk'
  return match(tool)
    .with('APPSCAN', () => ({ name: 'AppScan', logoHref: '/SastToolVisualAssets/AppScan-Icon.png' }))
    .with('CHECKMARX', () => ({ name: 'Checkmarx', logoHref: '/SastToolVisualAssets/Checkmarx-Icon.svg' }))
    .with('CODEQL', () => ({ name: 'CodeQL', logoHref: '/SastToolVisualAssets/CodeQL-Logo.svg' }))
    .with('CONTRAST', () => ({ name: 'Contrast', logoHref: `/SastToolVisualAssets/Contrast-Icon-${suffix}.svg` }))
    .with('DEFECT_DOJO', () => ({ name: 'DefectDojo', logoHref: '/SastToolVisualAssets/DefectDojo-Icon.svg' }))
    .with('PIXEE', () => ({ name: 'Pixee', logoHref: '/SastToolVisualAssets/Pixee-Icon.svg' }))
    .with('SEMGREP', () => ({ name: 'Semgrep', logoHref: '/SastToolVisualAssets/Semgrep-Icon.svg' }))
    .with('SNYK', () => ({ name: 'Snyk', logoHref: `/SastToolVisualAssets/Snyk-Icon-${suffix}.svg` }))
    .with('SONAR', () => ({ name: 'SonarQube', logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg` }))
    .exhaustive()
}

const ScanInfo = ({ scanAnalysis, theme }: { scanAnalysis: ScanAnalysis; theme: 'light' | 'dark' }) => {
  const { name, logoHref } = mapToolToLogoAndName(scanAnalysis.tool, theme)
  const scanDate = DateTime.fromISO(scanAnalysis.imported_at)

  return (
    <div className={styles.scanInfoContainer}>
      <h2 className={utilities.visuallyHidden}>Scan Information</h2>
      <img src={logoHref} alt={`${name} logo`} className={styles.toolLogo} />
      <div className={styles.dateContainer}>
        <p className={styles.dateText}>{scanDate.toFormat('M/d/yy')}</p>
        <p className={styles.timeText}>{scanDate.toFormat('hh:mm:ss a')}</p>
      </div>
      <p className={styles.branchTag} title={scanAnalysis.branch ?? ''}>
        {scanAnalysis.branch && scanAnalysis.branch.length > 25
          ? `${scanAnalysis.branch.slice(0, 22)}...`
          : (scanAnalysis.branch ?? scanAnalysis.sha)}
      </p>
    </div>
  )
}

const RepoAndOwnerInfo = ({ accountLogin, repositoryName }: { accountLogin: string; repositoryName: string }) => (
  <>
    <p className={styles.accountLoginText}>{accountLogin}</p>
    <p className={styles.repoNameContainer}>
      <MarkGithubIcon className={styles.scmIcon} />
      {repositoryName}
    </p>
  </>
)

const FindingsBar = ({
  fixesCount,
  totalFindings,
  tool,
}: {
  fixesCount: number
  totalFindings: number
  tool: Tool
}) => (
  <div
    role="img"
    aria-label={`${fixesCount} out of ${totalFindings} findings had available fixes`}
    className={styles.fixesContainer}
  >
    <div className={styles.bar}>
      {fixesCount === 0 ? (
        <div
          className={classNames(styles.barFilled[tool], styles.barEmpty, styles.roundedBar)}
          style={{ width: '100%' }}
        />
      ) : fixesCount === totalFindings ? (
        <div className={classNames(styles.barFilled[tool], styles.roundedBar)} style={{ width: '100%' }} />
      ) : (
        <>
          <div className={styles.barFilled[tool]} style={{ width: `${(fixesCount / totalFindings) * 100}%` }} />
          <div
            className={classNames(styles.barFilled[tool], styles.barEmpty)}
            style={{ width: `${((totalFindings - fixesCount) / totalFindings) * 100}%` }}
          />
        </>
      )}
    </div>
    <p className={styles.fixesText}>
      {fixesCount}/{totalFindings} <span className={styles.fixesTextLabel}>findings had available fixes</span>
    </p>
  </div>
)

const FindingsMetrics = ({ fixesCount, totalFindings }: { fixesCount: number; totalFindings: number }) => (
  <div className={styles.metricsContainer} role="group" aria-label="Findings Metrics">
    <output id="findings-count" className={styles.metricValue}>
      {totalFindings}
    </output>
    <label htmlFor="findings-count" className={styles.metricLabel}>
      findings
    </label>
    <output id="fix-coverage" className={styles.metricValue}>
      {totalFindings === 0 ? '0%' : `${Math.round((fixesCount / totalFindings) * 100)}%`}
    </output>
    <label htmlFor="fix-coverage" className={styles.metricLabel}>
      fix coverage
    </label>
  </div>
)
