import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import * as Tabs from '@radix-ui/react-tabs'
import { UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import * as analysisDetailsPageStyles from '../routes/analysis-details-page.css'
import { ChangesetBlocksPure, MarkdownWrapper } from '../routes/generate-single-fix-page'
import {
  ApiError,
  useGetChangesetsByAnalysisIdAndFindingId,
  useGetCodemodById,
  usePostPullRequestByFindingId,
} from '../utils/api-client/user-platform-api-hooks'
import { Changesets, CodemodWithDescription } from '../utils/api-client/user-platform-api-schemas'
import { Theme, useTheme } from '../utils/higher-order-components/with-theme'
import { HandleAddToastWithTimeout } from '../utils/higher-order-components/with-toasts'
import { CopyButton } from './copy-button'
import { DefaultButton } from './default-button'
import * as styles from './preview-fix-drawer.css'

export const PreviewFixDrawer: React.FC<{
  trigger: React.ReactNode
  analysisId: string
  scanId: string
  findingId: string
  handleAddToastWithTimeout: HandleAddToastWithTimeout
}> = ({ trigger, analysisId, scanId, findingId, handleAddToastWithTimeout }) => {
  const [open, setOpen] = useState(false)

  return (
    <PreviewFixDrawerDialogPure
      trigger={trigger}
      content={
        <PreviewFixDrawerContent
          analysisId={analysisId}
          scanId={scanId}
          findingId={findingId}
          handleAddToastWithTimeout={handleAddToastWithTimeout}
          handleCloseDialog={() => setOpen(false)}
        />
      }
      open={open}
      setOpen={setOpen}
    />
  )
}

export const PreviewFixDrawerPure: React.FC<{
  theme?: Theme
  trigger?: React.ReactNode
  changesets: Changesets
  codemod: CodemodWithDescription
}> = ({
  theme = 'dark',
  trigger = <button className={analysisDetailsPageStyles.fixPreviewButton}>Preview</button>,
  changesets,
  codemod,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <PreviewFixDrawerDialogPure
      trigger={trigger}
      content={
        <PreviewFixDrawerContentPure
          changesets={changesets}
          codemod={codemod}
          postPullRequestMutationResult={{
            mutate: () => {},
            mutateAsync: async () => {},
            isSuccess: false,
            isError: false,
            isIdle: true,
            isPending: false,
            status: 'idle',
            data: undefined,
            error: null,
            reset: () => {},
            variables: undefined,
            context: undefined,
            failureCount: 0,
            failureReason: null,
            isPaused: false,
            submittedAt: 0,
          }}
          theme={theme}
        />
      }
      open={open}
      setOpen={setOpen}
    />
  )
}

export const PreviewFixDrawerDialogPure: React.FC<{
  trigger: React.ReactNode
  content: React.ReactNode
  open: boolean
  setOpen: (open: boolean) => void
}> = ({ trigger, content, open, setOpen }) => {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen} modal={false}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      {open && content}
    </Dialog.Root>
  )
}

export const PreviewFixDrawerContent: React.FC<{
  analysisId: string
  scanId: string
  findingId: string
  handleCloseDialog: () => void
  handleAddToastWithTimeout: HandleAddToastWithTimeout
}> = ({ analysisId, scanId, findingId, handleCloseDialog, handleAddToastWithTimeout }) => {
  const { data: changesets } = useGetChangesetsByAnalysisIdAndFindingId({ analysisId, scanId, findingId })
  const { data: codemod } = useGetCodemodById({
    codemodId: changesets?.find(changeset => changeset.codemod_id)?.codemod_id,
  })
  const postPullRequestMutationResult = usePostPullRequestByFindingId({
    analysisId,
    scanId,
    findingId,
    onSuccess: () => {
      handleCloseDialog()
      handleAddToastWithTimeout({ message: <>Request to open PR received successfully!</>, variant: 'success' })
    },
    onError: (error: ApiError) => {
      handleAddToastWithTimeout({ message: <>Failed to open PR: {error.message}</>, variant: 'error' })
    },
  })
  const { theme } = useTheme()

  return (
    <PreviewFixDrawerContentPure
      changesets={changesets}
      codemod={codemod}
      postPullRequestMutationResult={postPullRequestMutationResult}
      theme={theme}
    />
  )
}

export const PreviewFixDrawerContentPure: React.FC<{
  changesets?: Changesets
  codemod?: CodemodWithDescription
  postPullRequestMutationResult: UseMutationResult<any, unknown, void, unknown>
  theme: Theme
}> = ({ changesets, codemod, postPullRequestMutationResult, theme }) => {
  const description = changesets?.find(changeset => changeset.description)?.description

  return (
    <Dialog.Portal>
      <Dialog.Content
        className={styles.content}
        onEscapeKeyDown={event => event.preventDefault()}
        onPointerDownOutside={event => event.preventDefault()}
        onInteractOutside={event => event.preventDefault()}
      >
        {codemod && (
          <div className={styles.header}>
            <Dialog.Title className={styles.title}>{codemod?.name}</Dialog.Title>
            <p className={styles.fixIdGroup}>
              <span className={styles.fixIdLabel}>FIX</span>
              <span>{codemod?.codemod_id}</span>
              <CopyButton text={codemod?.codemod_id ?? ''} label="FIX ID" />
            </p>
            <DefaultButton
              size="small"
              onClick={() => postPullRequestMutationResult.mutate()}
              state={postPullRequestMutationResult.isPending ? 'loading' : 'default'}
            >
              Open PR
            </DefaultButton>
          </div>
        )}
        <Dialog.Close asChild className={styles.closeButton}>
          <button aria-label="Close">
            <Cross2Icon className={styles.closeButtonIcon} />
          </button>
        </Dialog.Close>
        <Dialog.Description>
          <Tabs.Root defaultValue="overview" className={styles.tabContainer}>
            <Tabs.List className={styles.tabList}>
              <Tabs.Trigger className={styles.tab} value="overview">
                OVERVIEW
              </Tabs.Trigger>
              <Tabs.Trigger className={styles.tab} value="files-changed">
                FILES CHANGED <span className={styles.changesetCount}>{changesets?.length}</span>
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content className={styles.tabContent} value="overview">
              {description ? (
                <MarkdownWrapper
                  language={getCodemodLanguageByCodemodId(codemod?.codemod_id) ?? 'text'}
                  codemodDescription={description}
                  theme={theme}
                />
              ) : (
                <p className={styles.description}>No description available for this fix.</p>
              )}
            </Tabs.Content>
            <Tabs.Content className={styles.tabContent} value="files-changed">
              <ChangesetBlocksPure changesets={changesets ?? []} theme={theme} />
            </Tabs.Content>
          </Tabs.Root>
        </Dialog.Description>
      </Dialog.Content>
    </Dialog.Portal>
  )
}

export const getCodemodLanguageByCodemodId = (codemodId: string | undefined): string | undefined => {
  if (!codemodId) return undefined
  const [_, language] = codemodId.split(/:([^/]+)\//)
  return language
}
