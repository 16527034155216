import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useLocalStorage } from '@uidotdev/usehooks'
import React, { useState } from 'react'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { ErrorBoundary } from './error-boundary'
import { ApiError } from './utils/api-client/user-platform-api-hooks'
import { WithTheme } from './utils/higher-order-components/with-theme'
import { WithToasts } from './utils/higher-order-components/with-toasts'

export function Root() {
  const queryClient = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60_000, // 1 minute
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            throwOnError: (error: unknown) => {
              if (error instanceof ApiError && error.status === 499) return true
              if (error instanceof ApiError && error.status === 302) return true
              return false
            },
            retry: (failureCount, error) => {
              if (error instanceof ApiError && error.status === 403) return false
              if (error instanceof ApiError && error.status === 499) return false
              if (error instanceof ApiError && error.status === 302) return false
              return failureCount < 3
            },
          },
        },
        queryCache: new QueryCache(),
        mutationCache: new MutationCache(),
      })
  )[0]

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage: window.localStorage,
    })
  )

  const [isCheckInstallationsBreadcrumb, setIsCheckInstallationsBreadcrumb] = useLocalStorage<boolean>(
    'ui-state/isCheckInstallationsBreadcrumb',
    false
  )

  const location = useLocation()

  return (
    <ErrorBoundary locationPathname={location.pathname}>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <WithTheme>
          <WithToasts>
            <Outlet
              context={
                {
                  isCheckInstallationsBreadcrumb,
                  setIsCheckInstallationsBreadcrumb,
                } satisfies ContextType
              }
            />
          </WithToasts>
        </WithTheme>
      </PersistQueryClientProvider>
    </ErrorBoundary>
  )
}

type ContextType = {
  setIsCheckInstallationsBreadcrumb: React.Dispatch<React.SetStateAction<boolean>>
  isCheckInstallationsBreadcrumb: boolean
}

export function useOutletData() {
  return useOutletContext<ContextType>()
}
